<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<h4>Ошибка</h4>
				<p>Данная страница доступна только для зарегистрированных пользователей.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
